
import AutodeskSelection from "@/components/connectors/AutodeskSelection"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { Connector } from "@evercam/shared/types/connector"
import Vue from "vue"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useConnectorStore } from "@/stores/connector"
import { useProjectStore } from "@/stores/project"
import DropDownButton from "@/components/DropDownButton"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "SelectedImage",
  components: {
    AutodeskSelection,
    DropDownButton,
    EvercamLoadingAnimation,
  },
  props: {
    image: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      connectors: [
        {
          name: "Procore",
          value: Connector.Procore,
          logo: require("~/assets/img/procore_logo.png"),
        },
        {
          name: "Aconex",
          value: Connector.Aconex,
          logo: require("~/assets/img/aconex_logo.png"),
        },
        {
          name: "Autodesk",
          value: Connector.Autodesk,
          logo: require("~/assets/img/acc-logo.png"),
        },
      ],
      sendToFormDialog: false,
      isLoading: false,
      selectedProviderItem: "",
      selectedProviderItemName: "",
      selectedAconexProject: null,
      selectedProcoreProject: null,
      selectedProcoreProjectAlbum: null,
      selectedAutodeskHub: null,
      selectedAutodeskProject: null,
      selectedAutodeskFolder: null,
      uploadImageData: "",
    }
  },
  computed: {
    ...mapStores(useLayoutStore, useConnectorStore, useProjectStore),
    autodeskFolderName() {
      const selectedFolder = this.selectedAutodeskFolder?.[0]

      return selectedFolder
        ? (selectedFolder.parentName ?? "") + selectedFolder.text
        : null
    },
    canSendImage() {
      return (
        ((this.selectedAconexProject && this.isAconexActive) ||
          (this.selectedProcoreProject && this.isProcoreActive) ||
          (this.selectedAutodeskFolder && this.isAutodeskActive)) &&
        !this.isLoading
      )
    },
    isProcoreActive() {
      return (
        this.selectedProviderItem === Connector.Procore &&
        this.connectorStore.isProcoreConnected
      )
    },
    isAconexActive() {
      return (
        this.selectedProviderItem === Connector.Aconex &&
        this.connectorStore.isAconexConnected
      )
    },
    isAutodeskActive() {
      return (
        this.selectedProviderItem === Connector.Autodesk &&
        this.connectorStore.isAutodeskConnected
      )
    },
  },
  watch: {
    "connectorStore.uploadImageData": {
      handler(value) {
        this.uploadImageData = value
      },
      immediate: true,
    },
  },
  methods: {
    initDialog(provider) {
      this.$emit("update-image")
      this.selectedProviderItemName = provider.name
      this.selectedProviderItem = provider.value
      this.getProviderItem(provider)
      if (!this.connectorStore.connectorPromptDialog) {
        this.sendToFormDialog = true
      }
    },
    uploadImageToConnector() {
      if (this.selectedProviderItem === Connector.Procore) {
        this.uploadImageToProcore()
      } else if (this.selectedProviderItem === Connector.Aconex) {
        this.uploadImageToAconex()
      } else if (this.selectedProviderItem === Connector.Autodesk) {
        this.uploadImageToAutodesk()
      }
    },
    async uploadImageToAutodesk() {
      try {
        this.isLoading = true
        await EvercamApi.autodesk.uploadEditedSnapshot({
          projectExid: this.projectStore.selectedProject?.exid,
          snapshot: this.uploadImageData,
          folderId: this.selectedAutodeskFolder?.value,
          projectId: this.selectedAutodeskProject?.value,
          accFileType: this.selectedAutodeskFolder?.allowedFileType,
          accFolderType: this.selectedAutodeskFolder?.allowedFolderType,
        })
        this.$notifications.success(
          this.$t("content.connectors.upload_success")
        )
        this.selectedAutodeskHub = null
        this.selectedAutodeskProject = null
        this.selectedAutodeskFolder = null
        this.selectedProviderItem = null
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.upload_to_autodesk_failed"),
          error,
        })
      } finally {
        this.$emit("uploaded", { connector: Connector.Autodesk })
        this.sendToFormDialog = false
        this.isLoading = false
      }
    },
    async uploadImageToAconex() {
      try {
        this.isLoading = true
        await EvercamApi.aconex.uploadEditedSnapshot(
          this.selectedAconexProject.id,
          {
            projectExid: this.projectStore.selectedProject?.exid,
            snapshot: this.uploadImageData,
          }
        )
        this.$notifications.success(
          this.$t("content.connectors.upload_success")
        )
        this.selectedAconexProject = null
        this.selectedProviderItem = null
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.upload_to_aconex_failed"),
          error,
        })
      } finally {
        this.$emit("uploaded", { connector: Connector.Aconex })
        this.sendToFormDialog = false
        this.isLoading = false
      }
    },
    async uploadImageToProcore() {
      try {
        this.isLoading = true
        await EvercamApi.procore.uploadEditedSnapshot({
          projectExid: this.projectStore.selectedProject?.exid,
          projectId: this.selectedProcoreProject?.id,
          companyId: this.selectedProcoreProject?.company?.id,
          snapshot: this.uploadImageData,
          categoryId: this.selectedProcoreProjectAlbum?.id,
        })
        this.$notifications.success(
          this.$t("content.connectors.upload_success")
        )
        this.selectedProcoreProject = null
        this.selectedProviderItem = null
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.upload_to_procore_failed"),
          error,
        })
      } finally {
        this.$emit("uploaded", { connector: Connector.Procore })
        this.sendToFormDialog = false
        this.isLoading = false
      }
    },
    getProviderItem(item) {
      this.selectedProviderItem = item.value
      this.openConnectorPromptDialog()
    },
    openConnectorPromptDialog() {
      const shouldOpenConnectorPromptDialog =
        (this.selectedProviderItem === Connector.Procore &&
          !this.connectorStore.isProcoreConnected) ||
        (this.selectedProviderItem === Connector.Aconex &&
          !this.connectorStore.isAconexConnected) ||
        (this.selectedProviderItem === Connector.Autodesk &&
          !this.connectorStore.isAutodeskConnected)
      if (shouldOpenConnectorPromptDialog) {
        this.connectorStore.openConnectorPromptDialog(this.selectedProviderItem)
      }
    },
    async fetchProcoreProjectAlbums() {
      await this.connectorStore.getPorcoreProjectAlbums({
        companyId: this.selectedProcoreProject?.company?.id,
        projectId: this.selectedProcoreProject?.id,
      })
    },
  },
})
