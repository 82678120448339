
import { AnalyticsEvent, downloadTypes } from "@evercam/shared/types"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import DropDownButton from "@/components/DropDownButton"
import UploadImageToConnectors from "@/components/UploadImageToConnectors"

export default {
  components: {
    EvercamLoadingAnimation,
    DropDownButton,
    UploadImageToConnectors,
  },
  props: {
    isDownloading: {
      type: Boolean,
      default: false,
    },
    isSavingToMediahub: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDownloadJpeg: false,
      isDownloadPdf: false,
    }
  },
  computed: {
    downloads() {
      return [
        {
          name: "JPEG",
          value: downloadTypes.jpeg,
          logo: require("~/assets/img/jpeg.png"),
          isLoading: this.isDownloading && this.isDownloadJpeg,
        },
        {
          name: "PDF",
          value: downloadTypes.pdf,
          logo: require("~/assets/img/pdf.png"),
          isLoading: this.isDownloading && this.isDownloadPdf,
        },
      ]
    },
  },
  methods: {
    download(downloadItem) {
      this.isDownloadJpeg = downloadItem.value === downloadTypes.jpeg
      this.isDownloadPdf = downloadItem.value === downloadTypes.pdf
      if (downloadItem.value === downloadTypes.jpeg) {
        this.$analytics.saveEvent(AnalyticsEvent.editToolDownloadAs, {
          fileType: "jpeg",
        })
      } else if (downloadItem.value === downloadTypes.pdf) {
        this.$analytics.saveEvent(AnalyticsEvent.editToolDownloadAs, {
          fileType: "pdf",
        })
      }
      this.$emit("download", downloadItem)
    },
    saveToMediaHub() {
      this.$emit("save-to-mediahub")
    },
    saveUploadImageEvent(params) {
      this.$analytics.saveEvent(AnalyticsEvent.editToolSendToConnector, params)
    },
  },
}
