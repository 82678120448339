
import { MESSAGES_TYPES } from "@evercam/3d/components/constants"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"

export default {
  props: {
    origin: {
      type: String,
      default: "",
    },
    params: {
      type: String,
      default: "",
    },
    height: {
      type: [Number, undefined],
      default: undefined,
    },
    width: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data: () => ({
    iframeStyle: {
      border: "none",
    },
  }),
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
  },
  mounted() {
    this.$addEventListener("message", this.onMessageReceived)
  },
  methods: {
    async onMessageReceived(evt) {
      if (evt?.origin !== this.origin) {
        return
      }

      if (evt.data?.type === MESSAGES_TYPES.ready) {
        this.sendProjectInfo()

        return
      }

      this.$emit("on-message", { ...evt.data, origin: evt.origin })
    },
    async sendProjectInfo() {
      this.$refs.container?.contentWindow?.postMessage(
        {
          type: MESSAGES_TYPES.info,
          cameras: this.projectStore.selectedProjectCameras.reduce(
            (acc, value) => {
              acc[value.id] = value

              return acc
            },
            {}
          ),
          user: {
            email: this.accountStore.email,
            name: this.accountStore.fullname,
          },
        },
        this.origin
      )
    },
    updateFrameDimensions() {
      if (this.height && this.width) {
        this.iframeStyle = {
          height: `${this.height}px`,
          width: `${this.width}px`,
          border: "none",
        }

        return
      }

      const iframe = this.$refs.container
      const container = iframe?.getBoundingClientRect()
      this.$clearTimeouts()
      this.$setTimeout(() => {
        this.iframeStyle = {
          height: `${window.innerHeight - container?.top}px`,
          width: `${window.innerWidth - container?.left}px`,
          border: "none",
        }
      }, 100)
    },
  },
}
