
import { CameraStatus } from "@evercam/shared/types/camera"
import Vue from "vue"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"

export default Vue.extend({
  name: "CameraStatusSnackbar",
  computed: {
    ...mapStores(useCameraStore),
    showAlert() {
      return [
        CameraStatus.OFFLINE,
        CameraStatus.OFFLINE_SCHEDULED,
        CameraStatus.UNDER_MAINTENANCE,
        CameraStatus.WAITING_FOR_SITE_VISIT,
        CameraStatus.WAITING,
        CameraStatus.ON_HOLD,
      ].includes(this.cameraStore.selectedCamera?.status)
    },
  },
})
